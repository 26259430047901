$color-title: #5ad0e4;
#Detail{
	padding: 25px;
	padding-bottom: 50px;
	// border: 1px solid #efebeb;
	margin-bottom: 40px;
	.img-des{
		position: relative;
		.img-bg{
			width: 100%;
		}
		.des{
			position: absolute;
			padding: 40px;
			bottom: -115px;
			right: 40px;
			background: rgba($color1,.7);
			color: #fff;
			width: 45%;
			font-size: 15px;
			transition: .5s;
			img{
				position: absolute;
				top: 8px;
				left: 14px;
				z-index: 1;
			}
			span{
				z-index: 2;
				position:relative;
			}
		}
		.des.des-40{
			bottom: -40px;
		}
	}
	.info{
		padding: 50px 0px;
		span{
			padding: 10px 20px;
			img{
				margin-top: -5px;
				margin-right: 0px;
			}
		}
		span:first-child{
			border-top: 1px solid $color3;
			border-bottom: 1px solid $color3;
		}
		span:last-child{
			border-left: 1px solid $color3;
			border-top:1px solid $color3;
			border-bottom:1px solid $color3;
			margin-left: -4px;
		}
		.url-danhmuc{
			color: $color3;
			text-decoration: none;
		}
	}
	div.title{
		margin-top: 10px;
		h1.title{
			margin: 0px;
			color: $color3;
			font-size: 20px;
			position: relative;
			text-transform: uppercase;
			font-weight: bold;
			span.bg-title{
			}
		}
	}
	.social-des{
		margin-top: 15px;
		margin-bottom: 15px;
		padding-left: 5px;
		.social{
			width: 28%;
			float: left;
			position: relative;
			border: 1px dashed #c4c4c4;
			height: 60px;
			.fa{
				width: 30px;
				height: 30px;
				border-radius: 50%;
				text-align: center;
				line-height: 30px;
			}
			.fa-facebook{
				background: #3d67b4;
				color: #fff;
			}
			.fa-google-plus{
				color: #fff;
				background: #ef472e;
			}
			img{
				width: 15%;
				position: absolute;
				left: 5px;
				top: 10px;
			}
			.sdt1{
				position: absolute;
				left: 18%;
				top: 8px;
				font-size: 14px;
				color: #ef472e;
				font-weight: 800;
				small{
					font-size: 80%;
					color: #666666;
				}
			}
			.sdt2{
				position: absolute;
				left: 18%;
				top: 29px;
				font-size: 14px;
				color: #ef472e;
				font-weight: 800;
				small{
					font-size: 80%;
					color: #666666;
				}
			}
		}
		.des{
			width: 72%;
			margin-left: 30%;
			padding-left: 20px;
			border-left: 3px solid #d8d6d6;
			// font-style: italic;
			color: #1d93a1;
			font-weight: 600;
			padding-right: 20px;
			text-align: justify;
			height: 60px;
			overflow: hidden;
		}
	}
	.content{
		text-align: justify;
		line-height: 25px;
		font-size: 15px;
		a{
		  	color: #53bcce ;
		}
		p{
			margin-bottom: 10px;
		}
		table tr:first-child{
			background: #0d83be;
			td{
				color: #fff;
				p{
					margin-bottom:2px;
				}
			}
		}
		h1,h2,h3,h4,h5,h6{
			font-size: 16px !important;
		}
		@media(max-width: 1024px){
			img, input{
				width: 100% !important;
				height: auto !important;
			}
			table{
				width: 100% !important;
			}
		}
		.end{
			margin-top: 20px;
			background:url(../img/10.png);
			background-repeat: repeat-x;
			display: block;
			height: 6px;
		}

	}
}

@media(max-width: 768px){
	#Detail{
		padding: 25px 0px;
		.img-des{
			display: none;
		}
		.info{
			padding: 20px 0px;
		}
		div.title{
			h1.title{
				font-size: 22px;
				span.bg-title{
				}
			}
		}
		.social-des{
			.social{
				width: 50%;
				float: none;
				.fa{
					width: 30px;
					height: 30px;
					border-radius: 50%;
					text-align: center;
					line-height: 30px;
				}
				.fa-facebook{
					background: #3d67b4;
					color: #fff;
				}
				.fa-google-plus{
					color: #fff;
					background: #ef472e;
				}
				img{
					width: 10%;
					left: 15px;
					top: 8px;
				}
			}
			.des{
				width: 74%;
				display: none;
			}
		}
		.content{
			a{
			  	color: #53bcce;
			}
			p{
				margin-bottom: 2px;
			}
			@media(max-width: 1024px){
				img, input{
					width: 100% !important;
					height: auto !important;
				}
				table{
					width: 100% !important;
				}
			}

		}
	}
}


@media(max-width: 425px){
	#Detail{
		div.title{
			h1.title{
				font-size: 22px;
				span.bg-title{
				}
			}
		}
		.social-des{
			.social{
				width: 100%;
				float: none;
				img{
					width: 10%;
					left: 15px;
					top: 8px;
				}
			}
		}
	}
}
@media(max-width: 320px){
	#Detail{
		div.title{
			h1.title{
				font-size: 22px;
				span.bg-title{
				}
			}
		}
		.social-des{
			.social{
				width: 100%;
				float: none;
				img{
					width: 13%;
					left: 8px;
					top: 8px;
				}
			}
		}
	}
}