$color-title: #5ad0e4;

#Detail{
	.bx-wrapper{
		margin-left: 0px;
		margin-right: 0px;
	}
	.bx-wrapper .bx-controls-direction a{
		width: 50px;
		height: 17px;
		background:none;
		top: -12%;
	}
	.bx-wrapper .bx-prev{
		left: unset;
		right: 65px;
		border-top-right-radius: 7px;
		border-bottom-right-radius: 7px;

	}
	.bx-wrapper .bx-next{
		right: 0px;
		background-position-x: -2px;
		border-top-left-radius: 7px;
		border-bottom-left-radius: 7px;
	}
	h2.title-lienquan{
		margin:0px;
		padding:0px;
		color: $color3;
		border-bottom:0px;
		padding-top: 15px;
		padding-bottom: 25px;
		text-transform: uppercase;
		font-size: 20px;
	}
	ul.lienquan{
		padding: 0px;
		list-style-type: none;
		// border: 1.5px solid $color-title;
		li{
			// border: 1px solid #c1c1c1;
			a{
				text-decoration: none;
			}

				img{
					width: 100%;
					height: 148px;
				}
				.info{
					padding:0px;
					p{
						position: relative;
						font-size: 13px;
						color: #777;
						margin: 0px;
					}
					span{
						padding: 2px 12px;
						img{
							display: inline-block;
						}
					}
					span:first-child{
						padding: 2px 12px 2px 6px;
					}

					h3{
						font-size: 15px;
						color: #666;
						font-weight: bold;
						text-align: left;
						color: $color4;
						margin: 0px;
						margin-top: 5px;
					}
				}

		}
	}

}