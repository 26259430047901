$color1: #006aa5;
$color2: #000000;
$color3: #6d6e71;
$color4: #00aaff;
$color5: #509bbc;

@mixin css-content() {
  color: $color-content;
  text-align: justify;
  padding-bottom: 0px;
  font-family: 'Quicksand', sans-serif !important;
  p{
	  font-family: 'Quicksand', sans-serif !important;
  }
  span{
	  font-family: 'Quicksand', sans-serif !important;
  }
}

