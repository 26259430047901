#doitac{
	margin-top: 20px;
	h3.title{
		font-size: .46875in;
		color: #1aa5d8;
		text-transform: capitalize;
		font-weight: bold;
		text-align: center;
		.bc{
			color: #253d52;
		}
	}
	.title-line{
		margin-top: -.625pc;
		text-align: center;
		.tl-1,.tl-3{
			background: #eee;
			display: inline-block;
			height: .0625pc;
			width: 60pt;
		}
		.tl-2{
			border: .010416667in solid #ebc131;
			width: 9pt;
		    top: .052083333in;
		    height: .125in;
		    position: relative;
		    display: inline-block;
		    border-radius: 50%;
		}
	}
	.bx-wrapper{
		box-shadow: none;
		margin-top: 25px;
	}
	.bx-wrapper .bx-prev, .bx-wrapper .bx-next{
        background: transparent;
        color:#999;
        border-radius: 50%;
        text-align: center;
        line-height: 28px;
        font-size: 25px;
        text-indent: 0px;
    }
    .bx-wrapper .bx-prev:focus, .bx-wrapper .bx-next:focus{
        background: transparent !important;
        text-decoration: none;
    }
    .bx-wrapper .bx-prev{
        left: -37px;
    }
    .bx-wrapper .bx-next{
        right: -37px;
    }
}

@media(max-width: 768px){
	#doitac{
		h3.title{
			font-size: 23px;
		}
		.bx-wrapper .bx-controls-direction a{
			top: -20%;
			// width: 50px;
			display: none;
		}
		.bx-wrapper .bx-prev{
			left: unset;
			right: 85px;
		}
		.bx-wrapper .bx-next{
			right: 10px;
		}
	}
}