
header{
	// border-bottom: 4px solid #006aa5;
	.container-fluid{
		padding:0px;
		box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.23);
		background: transparent;
	}
	.navbar-fixed-top{
		.container-fluid{
			padding:0px;
			margin: auto;
			box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.23);

		}
		.navbar-header .navbar-brand{
			padding: 0px;
			width: 249px;
			padding-top: 7px;

		}
		.navbar-nav{
			li{
				padding:0px 25px;
				a{
					padding:10px 10px;
					color: #fff;
					font-size: 19px;
					text-transform: uppercase;
					text-decoration: none !important;
					position: relative;
					img{
						display: block;
						margin: auto;
						margin-bottom: 5px;
					}


					.bottom:before{
						position: absolute;
						content:'';
						transition: .5s;
						border-left: 10px solid transparent;
						border-right: 10px solid transparent;
	    				border-bottom: 0px solid $color4;
	    				bottom: -5px;
	    				left: 41%;
					}
				}
				a:hover, a:focus{
					background: transparent;

					.bottom:before{
	    				border-bottom: 10px solid #fff;
	    				top: -5px;
					}
				}
			}
			li.active{
				a{

					.bottom:before{
	    				border-bottom: 10px solid #fff;
	    				top: -5px;
					}
				}
			}
		}
	}
	.navbar-header{
		width: 30%;
		padding-right: 5%;
		background: #0d83be;
		padding: 5px;
		.navbar-brand{
			float: left;
			width: 248px;
			padding:0px;
			height: auto;
			padding-top: 8px;
			margin-left: 30px;
			img{
				width: 100%;

			}
		}
	}
	#menu-mobile{
		width: 70%;
		padding: 5px;
		padding-left:3%;
		background: #0d83be;
	}
	.navbar{
		margin: 0px;
	}
	.navbar-nav{
		float: right;
		li{
			padding:0px 25px;
			a{
				padding:10px 10px;
				color: #fff;
				font-size: 19px;
				text-transform: uppercase;
				text-decoration: none !important;
				position: relative;
				img{
					display: block;
					margin: auto;
					margin-bottom: 7px;
				}

				.bottom{
					position: absolute;
					content: '';
					width: 100%;
					height: 0px;
					background: $color4;
					bottom: -0px;
					left: 0px;
					transition: .5s;
					border-bottom-right-radius: 4px;
					border-bottom-left-radius: 4px;
				}
				.bottom:before{
					position: absolute;
					content:'';
					transition: .5s;
					border-left: 10px solid transparent;
					border-right: 10px solid transparent;
    				border-bottom: 0px solid $color4;
    				bottom: -5px;
    				left: 41%;
				}
			}
			a:hover, a:focus{
				background: transparent;

				.bottom:before{
    				border-bottom: 10px solid #fff;
    				top: -5px;
				}
			}
		}
		li.active{
			a{

				.bottom:before{
    				border-bottom: 10px solid #fff;
    				top: -5px;
				}
			}
		}
	}
	.form-search{
		float: right;
		margin-top: 5px;
		input{
			border: 0px;
			border-bottom: 1px solid #c4c4c4;
			height: 31px;
		}
		input:focus{
			outline: 0;
		}
		button{
			background: transparent;
			border:0px;

		}
	}
}
@media(max-width:767px){
	header{
		.navbar-toggle{
			border-color: #fff;
			margin-top: 19px;
			margin-right: 0px;
			.icon-bar{
				background: #fff;
			}
		}
		.navbar-header{
			width: 100%;
			padding-left: 5%;
			.navbar-brand{
				float: left;
				width: 60%;
				padding:0px;
				height: auto;
				padding-top: 8px;
				img{
					width: 100%;
				}
			}
		}
		#menu-mobile{
			position: absolute;
			z-index: 15;
			background: $color1;
			width: 100%;
			padding: 0px;
			margin-left: 00px;
			// margin-top: 14px;
			.navbar-nav{
				margin: 0px;
			}
			ul{
				li{
					float: left;
					width: 50%;
					text-align: center;
					padding: 25px 20px;
					a{
						color:#fff;
					}
				}
				li:nth-child(2),li:nth-child(3),li:nth-child(6){
					background:$color5;
				}
			}
		}
	}

}