#tt{
	margin-top: 20px;

	a{
		text-decoration: none;
	}
	h2.title{
		font-size: .46875in;
		color: #1aa5d8;
		text-transform: capitalize;
		font-weight: bold;
		text-align: center;
		.bc{
			color: #253d52;
		}
	}
	.title-line{
		margin-top: -.625pc;
		text-align: center;
		.tl-1,.tl-3{
			background: #eee;
			display: inline-block;
			height: .0625pc;
			width: 60pt;
		}
		.tl-2{
			border: .010416667in solid #ebc131;
			width: 9pt;
		    top: .052083333in;
		    height: .125in;
		    position: relative;
		    display: inline-block;
		    border-radius: 50%;
		}
	}
	.tt{
		margin-top: 20px;
		width: 31.1164522171%;
		display: inline-block;
		border-bottom: 3px solid $color1;
		padding-bottom: 10px;
		margin-right: 2%;
		.box-img-tt{
			position: relative;
			display: block;
			.img-tt{
				width: 100%;
			}
			.date{
				position: absolute;
				top: -15px;
				right: 5px;
				padding: 6px 18px;
				border: 1.5px solid #fff;
				border-bottom-left-radius: 20px;
				border-bottom-right-radius: 20px;
				color: #fff;
				background: $color4;
				z-index: 5
			}
			.bg{
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background: rgba($color2, .5);
				text-align: center;
				vertical-align: middle;
				opacity: 0;
				transition: .5s;
				z-index: 4;
				img{
					margin-top: 17%;
					transition: .5s;
				}
			}
		}
		.box-img-tt:hover{
			.bg{
				opacity: 1;
				img{
					transform: rotate(180deg);
				}

			}
		}
		$font-size: 18px;
		$line-height: 1.3;
		$lines-to-show: 1;
		.info{
			h3.title{
				color: $color1;
				text-transform: uppercase;
				font-weight: bold;
				-webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                height: $font-size*$line-height*$lines-to-show;
                font-size: $font-size;
                line-height: $line-height;
                -webkit-line-clamp: $lines-to-show;
                text-align: left;
                margin-top: 10px;
                margin-bottom: 5px;
                .price{
                	float: right;
                	color: red;

                }
			}
			$font-size: 14px;
			$lines-to-show: 3;
			.content{
				-webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                height: $font-size*$line-height*$lines-to-show;
                font-size: $font-size;
                line-height: $line-height;
                -webkit-line-clamp: $lines-to-show;
                text-align: justify;
                .price{
                	color: red;
                }
			}
			.ct-social{
				margin-top: 10px;
				.l50{
					display: inline-block;
					width: 49%;
				}
				.share-social{
					a{
						img{
							// width: 50px;
							margin-top: -4px;
						}
					}
				}
				.ct{
					text-align: right;
					font-size: 16px;
					color: $color1;
					img{
						width: 30%;
					}
				}
			}
		}
	}
	.tt:last-child{
		margin-right:0px;
	}
}

@media(max-width: 768px){
	#tt{
		h2.title{
			font-size: 23px;
		}
		.tt{
			width: 30%;
		}
	}

}

@media(max-width: 425px){
	#tt{
		.tt{
			width: 100%;
			margin-bottom: 15px;
		}
	}
}




#noidia{
	margin-top: 20px;

	a{
		text-decoration: none;
	}
	h2.title{
		font-size: .46875in;
		color: #1aa5d8;
		text-transform: capitalize;
		font-weight: bold;
		text-align: center;
		.bc{
			color: #253d52;
		}
	}
	.title-line{
		margin-top: -.625pc;
		text-align: center;
		.tl-1,.tl-3{
			background: #eee;
			display: inline-block;
			height: .0625pc;
			width: 60pt;
		}
		.tl-2{
			border: .010416667in solid #ebc131;
			width: 9pt;
		    top: .052083333in;
		    height: .125in;
		    position: relative;
		    display: inline-block;
		    border-radius: 50%;
		}
	}
	.col-md-3{
		.noidia{
			.info{

				h5{
					font-size: 1.125pc;
				}
			}
		}
	}
	.noidia:hover{
		.box-img-tt:after{
			background:linear-gradient(to top,rgba(0,0,0,.82) 10%,rgba(84,84,84,.35) 80%)
		}
	}
	.noidia{
		border-radius: 5px;
		margin-top: 9px;
		display: inline-block;
		// border-bottom: 3px solid $color1;
		padding-bottom: 10px;
		position: relative;

		.box-img-tt{
			position: relative;
			display: block;
			.img-tt{
				width: 100%;
				border-radius: 5px;
			}
		}
		.box-img-tt:after{
			position: absolute;
			transition: .5s;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			content:'';
			border-radius: 5px;
			background: rgba(0,0,0,.7);
		}

		$font-size: 18px;
		$line-height: 1.3;
		$lines-to-show: 1;
		.info{
			padding: 20px;
			position: absolute;
			left: 0px;
			bottom: 0px;
			width: 100%;
			h5{
				color: #fff;
				// text-align: right;
				font-size: 1.625pc;
				margin-bottom: 5px;
				font-weight: bold;
			}
			p{
				// text-align: right;
				color: #c1c1c1;
				font-size: 0.125552in;
				.price{
					color: red;
					font-weight: bold;
					font-size: 17px;
				}
			}
			$font-size: 14px;
			$lines-to-show: 3;
			.content{
				-webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                height: $font-size*$line-height*$lines-to-show;
                font-size: $font-size;
                line-height: $line-height;
                -webkit-line-clamp: $lines-to-show;
                text-align: justify;
                .price{
                	color: red;
                }
			}
			.ct-social{
				margin-top: 10px;
				.l50{
					display: inline-block;
					width: 49%;
				}
				.share-social{
					a{
						img{
							// width: 50px;
							margin-top: -4px;
						}
					}
				}
				.ct{
					text-align: right;
					font-size: 16px;
					color: $color1;
					img{
						width: 30%;
					}
				}
			}
		}
	}
	.noidia:last-child{
		margin-right:0px;
	}
}

@media(max-width: 768px){
	#noidia{
		h2.title{
			font-size: 23px;
		}

	}

}

@media(max-width: 425px){
	#noidia{
		.noidia{
			.info{

				h5{
					font-size: 1.125pc;
				}
			}
		}
	}
}