.bx-wrapper{
	box-shadow: none;
	border: 0px;
	.bx-viewport{
		max-height: 550px !important;
	}
	img{
		width: 100%;
	}
	.text-slide{
		position: absolute;
		width: 100%;
		top: 10%;
		.plane, .arrow-down{
			display: block;
			width: auto;
			margin: auto;
		}
		.plane{
			margin-top: 50px;
		}
		.arrow-down{
			margin-top: 140px;
		}
		.logan{
			font-size: 26px;
			text-align: center;
			color: #fff;
			text-transform: uppercase;
			margin-top: 30px;
		}
	}
}
@media(max-width:767px){
	.bx-wrapper{
		.text-slide{
			display: none;
		}
	}
}
@media(max-width: 425px){
	.box-slider{
		display: none;
	}
}