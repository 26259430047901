.baohiem{
	padding: 10px;
	border: 1px solid $color4;
	padding-top:20px;
	position: relative;
	margin-top: 30px;
	div.title{
		h2.title{
			font-weight: normal;
			text-transform: uppercase;
			color: #555;
			font-size: 22px;
			position: absolute;
			width: 100%;
			top: -19px;
			left: 0px;
			margin:0px;
			span.bg-title{
				margin:auto;
				text-align: center;
				background: #fff;
				padding: 6px 12px;
				display: table;

			}
		}
	}
	div.img{
		margin-top: 10px;
		margin-bottom: 5px;
		img{
			width: 100%;
			height: auto;
		}
	}
}

.khuyenmai{
	padding: 10px;
	border: 1px solid $color4;
	padding-top:20px;
	position: relative;
	margin-top: 30px;
	margin-bottom: 30px;
	div.title{
		h2.title{
			color: #555;
			font-size: 22px;
			position: absolute;
			width: 100%;
			top: -19px;
			left: 0px;
			margin:0px;
			text-transform: uppercase;
			span.bg-title{
				margin:auto;
				text-align: center;
				background: #fff;
				padding: 6px 12px;
				display: table;

			}
		}
	}
	ul.km{
		list-style-type: none;
		padding:0px;
		li{
			margin: 10px 0px;
			position: relative;
			padding: 10px 5px;
			padding-bottom: 20px;
			// border-bottom: 1px solid #dedede;
			a{
				color: #777;
				img{
					width: 30%;
				}
				p{
					width: 69%;
					position: absolute;
					left: 32%;
					bottom: 15px;
					margin: 0px;
				}
			}

		}
	}

}