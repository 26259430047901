footer{
	background:$color1;
	color: #fff;
	padding: 20px 0px;
	.border-right-left{
		border-right: 1px solid #fff;
		border-left: 1px solid #fff;
	}
	.border-right{
		border-right: 1px solid #fff;
	}
	.box-30-l{
		img{
			width: auto;
		}
	}
	h5{
			// font-weight: bold;
			font-size: 18px;
			padding-bottom: 7px;
			border-bottom: 1px solid #fff;
		}
	.info{
		padding: 0px 20px;

		p{
			margin: 5px;
			i{
				margin-right: 5px;
			}
		}
		.social{
			a {
				i{
					width: 30px;
					height: 30px;
					background: #fff;
					color: $color4;
					border-radius: 50%;
					text-align: center;
					font-size: 16px;
					line-height: 30px;
				}
			}
		}
	}
	.lk{
		h5{
			padding-left: 20px;
		}
	}
	.fb{
		padding-left: 80px;
		padding-top: 30px;
		span{
			text-align: center;
			padding: 15px 30px;
			font-size: 30px;
			background: $color4;
		}
	}
}
footer.fix-bottom{
	position: fixed;
	bottom: 0px;
	left: 0px;
	width: 100%;
}
@media(max-width: 768px){
	footer{
		.box-25-l.logo{
			padding-top: 20px;
			padding-right: 20px;
			.logo{
				width: 100%;
			}
		}
		.fb{
			padding-left: 40px;
		}

	}
}

@media(max-width: 425px){
	footer{
		.border-right-left{
			border:0px;
		}
		.border-right{
			border:0px;
		}
		.box-30-l{
			width: 100%;
		}
		.box-25-l.logo{
			padding: 0px;
			.logo{
				width: auto;
				display: block;
				margin: auto;
			}
		}
		.info{
			padding: 20px 0px;
		}
		.lk{
			display: none;
		}
		.fb{
			padding: 0px;
			display: none;
			span{
				margin: auto;
				display: block;
				width: 190px;

			}

		}
	}
}