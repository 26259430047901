#gioithieu{
	margin-top:40px;
	.box-50-l{
		img{
			margin-top: 15px;
		}
	}
	.gioithieu{
		padding-left: 25px;
		h2.title{
			text-transform: uppercase;
			.small{
				font-size: 14px;
				margin: 0px;
			}
			.large{
				font-size: 26px;
				color: $color1;
				font-weight: bold;
				position: relative;
			}
			.large:after{
				position: absolute;
				content:'';
				left: 0;
				bottom:-13px;
				width: 50%;
				height: 10px;
				background: $color4;
			}
		}
		.content{
			margin-top: 20px;
			max-height: 185px;
			overflow-y:scroll;
			padding-right: 7px;

		}
		.content::-webkit-scrollbar{
			width: 10px;
			background-color:transparent;
		}
		.content::-webkit-scrollbar-track{
			-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
			border-radius: 10px;
			background-color: $color1;
		}
		.content::-webkit-scrollbar-thumb{
			border-radius: 10px;
			-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
			background-color: $color4;
		}
	}

}
@media(max-width: 768px){
	#gioithieu{
		.box-50-l.img{
			display: none;
		}
		.box-50-l{
			width: 100%;
		}
		.gioithieu{
			padding-left:0px;
			.content{
				max-height: unset;
				overflow-y: auto;
			}
		}
	}
}