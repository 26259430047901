.box-search{
	.directory{
		.radio{
			display: inline-block;
			padding: 6px 12px;
			border: 1px solid $color1;
			background: #fff;
			margin-right: 3px;
		}
		.radio:hover{
			cursor: pointer;
		}
		.radio.checked{
			background: $color1;
			color: #fff;
		}
	}
	.input-search{
		width: 100%;
		height: 30px;
		border-top: 0px;
		border-left: 0px;
		border-right: 0px;
	}
	.input-search:focus{
		outline: 0;
	}
	span{
		position: absolute;
		width: 26px;
		height: 26px;
		right: 0px;
		top: 1px;
	}
	.depart-return{
		.depart, .return{
			margin-bottom: 10px;
			position: relative;
		}
	}
	.time{
		.depart, .return{
			position: relative;
			display: inline-block;
			width: 50%;
			margin-bottom: 10px;
			// transition: .5s;
		}
		.depart{
			padding-right: 7px;
		}
		.return{
			padding-left: 7px;
			float: right;
		}
		.depart.full-width{
			width: 100%;
		}
	}
	.persons{
		.person{
			position: relative;
			margin-bottom: 10px;
		}
	}
	.timve{
		.btn-timve{
			background: #fff;
			border: 0px;
			font-size: 16px;
			float: right;
			padding: 0px;
			color: $color3;
			font-weight: bold;
			img{
				display: inline-block;
				width: 50px !important;
				margin-top: -5px;
			}
		}

	}

}