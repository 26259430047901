@import url("https://fonts.googleapis.com/css?family=Quicksand");

// Fonts
// @import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body{
    background: #fff;
    font-family: Quicksand;
}
@media(min-width: 1200px){
    .container{
        width: 1200px !important;
    }
}
@media(max-width:768px){
	.display-mobile{
		display: grid;
	}
	.order-1{
		order:1;
	}
	.order-2{
		order:2;
	}
	.order-3{
		order: 3;
	}
	.order-4{
		order: 4;
	}
}
.box-50-l{
    float: left;
    width: 50%;
}

.box-30-l{
	float: left;
	width: 33.33%;
}
.box-25-l{
	float: left;
	width: 25%;
}
.mg-bt-20{
	margin-bottom: 20px;
}
.pd-bt-10{
	padding-bottom: 10px;
}
.show-mobile{
	display:none !important;
}
@media(max-width: 768px){
	.show-mobile{
		display: block !important;
	}
}
.loader{
	position: fixed;
	width: 100%;
	height: 100%;
	background: #fff;
	vertical-align: middle;
	align-items: center;
	top:0px;
	left:0px;
	z-index: 9999999999;
	.img{
		display: grid;
		grid-template-columns: 100%;
		grid-template-rows: 100%;
		justify-items: center;
		align-items: center;
		height: 100%;

	}
}
@import "modules/header";
@import "modules/slide";
@import "modules/dichvu";
@import "modules/gioithieu";
@import "modules/tintuc";
@import "modules/doitac";
@import "modules/footer";
@import "modules/box-search";


@import "modules/list";
@import "modules/detail";
@import "modules/sidebar";
@import "modules/lienquan";

.lienhe{
	margin-top: 10px;
	.small{
		font-size: 16px;
		margin: 0px;
	}
	.large{
		font-size: 30px;
		color: $color1;
		font-weight: bold;
		position: relative;
	}
	.p{
		margin-bottom: 15px;
		i{
			background: $color1;
			border-radius: 50%;
			margin-right: 5px;
			color: #fff;
			width:30px;
			height: 30px;
			text-align: center;
			padding: 10px 8px;
		}
	}
	.p.mg-t-10{
		margin-top: 10px;
	}
}