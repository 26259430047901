#dichvu{
	margin-top: 40px;
	.box-50-l{
		img{
			width: 100%;
		}
	}
	.dv{
		padding: 10px 20px;
	    box-shadow: 0px 5px 20px 0px #9d9b9b;
	    border-radius: 5px;
	    margin-bottom: 25px;
		img{
			width: auto;
			display: block;
			margin: auto;
		}
		h5{
			font-weight: bold;
			font-size: 18px;
			text-align: center;
		}
	}
}
@media(max-width:767px){
	#dichvu{
		.box-50-l.img{
			display: none;
		}
		.box-50-l{
			width: 100%;
		}
	}
}