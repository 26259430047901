
.list-col-3{
	margin-top: 20px;
	.title-page, .childs{
		text-align: center;
	}
	.title-page{
		font-size: 20px;
		text-transform: capitalize;
		font-weight: bold;
		margin-bottom: 15px;
		color:#1aa5d8;
		.bc{
			color:#253d52
		}
		.title-line{
			margin-top: -.625pc;
			text-align: center;
			.tl-1,.tl-3{
				background: #eee;
				display: inline-block;
				height: .0625pc;
				width: 60pt;
			}
			.tl-2{
				border: .010416667in solid #ebc131;
				width: 9pt;
				top: .052083333in;
				height: .125in;
				position: relative;
				display: inline-block;
				border-radius: 50%;
			}
		}
	}
	.childs{
		margin-bottom: 20px;
		a{
			text-decoration: none;
		}
		a:hover, a.active{
			.child{
				color: #fff;
				background: #0f82be;
			}
		}
		.child{
			margin-right: 5px;
			font-size: 16px;
			color: #0f82be;
			border: 1px solid #0f82be;
			border-radius: 3px;
			padding: 6px 12px;
			text-transform: capitalize;
		}
	}
	.acticle{

		.box-50-l{
			.d-img{
				padding-right: 20px;
				img{
					width: 100%;
				}
			}
			.d-info{
				border-bottom: 3px solid $color1;
				$font-size: 17px;
				$line-height: 1.4;
				$lines-to-show: 2;
				h2.title{
					margin: 0px;
					margin-bottom: 8px;
					line-height: 17px;
					// overflow: hidden;
	    //             text-overflow: ellipsis;
	    //             height: $font-size*$line-height*$lines-to-show;
	    //             font-size: $font-size;
	    //             line-height: $line-height;
	    //             -webkit-line-clamp: $lines-to-show;
	    //             text-align: left;
					a{
						font-size: 17px;
						color: $color3;
						font-weight: bold;
						text-decoration: none;
					}
				}
				$font-size: 15px;
				$line-height: 1.4;
				$lines-to-show: 3;
				.des{
					overflow: hidden;
	                text-overflow: ellipsis;
	                height: $font-size*$line-height*$lines-to-show;
	                font-size: $font-size;
	                line-height: $line-height;
	                -webkit-line-clamp: $lines-to-show;
	                text-align: left;
				}
				.date{
					margin-top: 10px;
					.chitiet{
						float:right;
						color: $color4;
						text-transform: uppercase;
						font-weight: bold;
						a{
							text-decoration: none !important;;
						}
					}
				}
				.social{
					position: relative;
					padding-left: 5px;
					.bg{
						position: absolute;
						width: 40px;
						height: 15px;
						top: 7px;
						left: -40px;
						background: $color4;
						display: none;
					}
				}

			}
		}
	}
}

@media(max-width:425px){
	.list-col-3{
		margin-top: 20px;
		.title-page{
			font-size: 17px;
		}
		.childs .child{
			font-size: 10.7px;
		}
		.acticle{
			.box-50-l{
				padding: 0px 20px;
				width: 100%;
				.d-img{
					padding:0px;
				}
				.social{
					.bg{
						display: none;
					}
				}
				.des{
					text-align: justify;
				}
			}
		}
	}
}